<template>
  <div class="supplydemand">
    <div class="supplydemand-left">
      <div class="supplydemand-left-header">
        <div class="first-classify-box">
          <div class="first-classify" :class="classifytext == item.title ? 'active-classifytext' : null"
            v-for="(item, index) in classifylist" @click="changefirstbtn(item, index)" :key="index">
            {{ item.title }}
          </div>
        </div>
        <div class="second-classify-box">
          <div class="second-classify" v-for="(item, index) in resultlist" @click="changesecondbtn(item)"
            :class="resulttext == item.title ? 'active-second-classify' : null" :key="index">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="message-totle">共 {{ total }} 条</div>
      <div class="main-body">
        <div class="main-body-items" v-for="(item, index) in Supplylist" :key="index" @click="godetails(item, 3)">
          <div class="title-box">
            <div class="icon">
              <!-- 多个三元 -->
              <!-- 条件?'' :条件? '' : '' -->
              <span :class="item.articleFields[1].fieldValue == '1'
              ? 'gong'
              : item.articleFields[1].fieldValue == '2'
                ? 'xu'
                : 'pin'
              " class="title-icon">{{
              item.articleFields[1].fieldValue == "1"
                ? "供"
                : item.articleFields[1].fieldValue == "2"
                  ? "需"
                  : "聘"
            }}</span>
            </div>
            <div class="title">
              {{ item.title }}
            </div>
          </div>
          <!-- <div class="author-details">
            <div class="author-box">
              <p class="author-name" v-if="item.author">{{ item.author }}</p>
            </div>
          </div> -->
          <div class="textarea-box">
            <div class="img-box">
              <img :src="global.imgUrl + item.imgUrl" alt="" />
            </div>
            <div class="textarea-details">
              <p>
                {{ item.zhaiyao }}
              </p>
              <div class="author-details">
                <div class="author-box">
                  <p class="author-name" v-if="item.author">
                    {{ item.author }}
                  </p>
                  <div class="author-time">
                    {{ item.addTime.slice(0, 11) }}
                  </div>
                </div>
                <div class="Operationtype">
                  <!-- <div @click.stop="dianzan(item.id)">
                    <img :src="imgIcon.imgZan" />
                    <span class="count">{{ item.likeCount }}</span>
                  </div> -->

                  <div @click.stop="shoucang(item.id)" v-if="item.isFav">
                    <!-- <i class="iconfont icon-xiai"></i> -->
                    <img :src="imgIcon.imgCangTrue" />
                    <span class="count">已收藏</span>
                  </div>
                  <div @click.stop="shoucang(item.id)" v-else>
                    <!-- <i class="iconfont icon-xiai"></i> -->
                    <img :src="imgIcon.imgCang" />
                    <span class="count">收藏</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="textarea-date-box">
            <div class="Operationtype">
              <div @click="dianzan(item.id)">
                <i class="iconfont" v-if="item.xin"></i>
                <i class="iconfont icon-dianzan"></i>
                <span class="count">{{ item.likeCount }}</span>
              </div>
              <div @click="shoucang(item.id)">
                <i class="iconfont icon-xiai"></i>
                <span class="count">{{ item.favCount }}</span>
              </div>
            </div>
            <div>{{ item.addTime.slice(0, 11) }}</div>
          </div> -->
        </div>
      </div>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <div class="supplydemand-right">
      <!-- <div class="release-button" @click="showrelease">发布供需</div> -->

      <!-- <el-dialog
        title="发布供需"
        :visible.sync="dialogVisible"
        width="70%"
        :before-close="handleClose"
      >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submituserinfo">确 定</el-button>
        </span>
      </el-dialog> -->

      <div class="demand-add" @click="onDemandAdd">
        <div class="mc-full-button">
          <div class="mc-full-button-icon">
            <img src="https://www.foodtalks.cn/wefood/static/img/publish.4fb44e7c.svg" />
            <span>发布供需</span>
          </div>
        </div>
      </div>

      <div class="hot-demand">
        <div class="hot-demand-header">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-zu344"></use>
          </svg>
          热门供需
        </div>
        <div class="hot-demand-item" v-for="(item, index) in hotsupplylist" @click="godetails(item, 3)">
          <div :class="item.articleFields[0].fieldValue == '1'
              ? 'icon-point'
              : 'icon-need-point'
              ">
            {{ item.articleFields[0].fieldValue == "1" ? "供" : "需" }}
          </div>
          <p class="hot-demand-title">
            {{ item.title }}
          </p>
        </div>
      </div>
      <div class="group-box">
        <!-- <div class="group-header">
          <p class="header-title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            圈子
          </p>
          <div class="see-more">查看更多</div>
        </div> -->
        <div class="group-item">
          <!-- <div class="group-items" v-for="item in grouplist" @click="goCircleDetails(item)">
            <i class="iconfont icon-a-zu338"></i>
            <svg class="icon icon-a-zu337" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <div class="join"
              @click="$store.commit('setVideoDialog', { show: true, url: item.linkUrl, title: item.title })"></div>
            <div class="join">
              <i class="iconfont icon-a-zu340"></i>
              加入
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="follow-type">
        <div class="follow-type-header">
          <div class="join-people">
            <div :class="peopletype == '最新加入' ? 'active-people' : null">
              最新加入
            </div>
            <div :class="peopletype == '最受关注' ? 'active-people' : null">
              最受关注
            </div>
          </div>
          <div class="cheacd-all">查看全部</div>
        </div>
        <div class="follow-type-items" v-for="(item, index) in 10">
          <div class="items-img">
            <img src="@/assets/images/zjtx.png" alt="" />
          </div>
          <div class="user-introduce">
            <p>冯坤</p>
            <p>沪鑫堡展览（上海）有限公司 - 市场总监</p>
          </div>
         
        </div>
      </div> -->
      <!-- <div class="cooperation">
        <div class="cooperation-header">
          <div>商务合作</div>
        </div>
        <div class="advertisement">
          <div>
            <img src="@/assets/images/swhz.png" alt="" />
          </div>
          <div>
            <p>公众号&社群推广</p>
            <p>200+社群，5W+社群成员</p>
          </div>
        </div>
        <div class="official-account">
          <div>集成电路圈公众号</div>
          <div>关注</div>
        </div>
      </div> -->
      <div class="join-Groupchat">
        <div>加入供需社群</div>
        <div class="ewmcode-box">
          <!-- lxrewm == 供需群二维码 -->
          <div class="ewmcode">
            <div class="gxqewm">
              <img src="https://mcnki.obs.cn-east-2.myhuaweicloud.com/upload/202404/08/83dd7bcb-ea86-4940-a80c-6407e33741cc.jpg" alt="" />
            </div>
            <img src="@/assets/images/ewmicon.png" alt="" />
            <br /><br />
            联系人
          </div>
          <div class="ewmcode">
            <!-- lxrewm == 联系人二维码 -->
            <!-- <div class="lxrewm">
              <img src="https://mcnki.obs.cn-east-2.myhuaweicloud.com/upload/202404/08/83dd7bcb-ea86-4940-a80c-6407e33741cc.jpg" alt="" />
            </div>
            <img src="@/assets/images/ewmicon.png" alt="" />
            <br /><br />
            联系人
            -->
          </div>
        </div>
      </div>
    </div>
    <video-dialog />
  </div>
</template>

<script>
import {
  getSupplylist,
  Grouplist,
  getSupplyclassification,
  getSupplyclassifyid,
  gethotsupply,
  putLike,
  putFav,
  fav,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "supplydemand",
  components: {
    videoDialog: () => import("@/components/Circle"),
  },
  data() {
    return {
      classifytext: "热门",
      classifylist: [],
      resultlist: [],
      resulttext: "全部",
      currentPage: 1,
      peopletype: "最新加入",
      Supplylist: [],
      mySupplylist: [],
      grouplist: [],
      total: 1,
      hotsupplylist: [],
      dialogVisible: false,
      saveClassId: 0,
      imgIcon: imgObj,
    };
  },
  methods: {
    onDemandAdd() {
      if (window.localStorage.getItem("token")) {
        this.$router.push("/release");
      } else {
        this.$message({
          message: "请先登录后再发布供需",
          type: "warning",
        });
        this.$router.push("/login");
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    async getgrouplist() {
      Grouplist().then((res) => {
        console.log("圈子标题", res);
        this.grouplist = res.data;
      });
    },
    showrelease() {
      let token = window.localStorage.getItem("token");
      let username = window.localStorage.getItem("userName");

      this.$router.push("/release");

      // if (token && username) {
      //   this.$router.push("/release");
      // } else {
      //   this.$message({
      //     message: "请先登录后，才能发布供需",
      //     type: "warning",
      //   });
      //   setTimeout(() => {
      //     location.reload();
      //   }, 1000);
      // }
    },
    handleSizeChange(e) {
      console.log(e);
    },
    handleCurrentChange(e) {
      console.log(e);
    },
    changefirstbtn(item, index) {
      this.classifytext = item.title;
      this.resultlist = this.classifylist[index].children;
      this.resulttext = "全部";
      this.saveClassId = item.id;
      this.changelist(item.id);
    },
    changesecondbtn(item) {
      this.resulttext = item.title;
      this.changelist(item.id);
    },
    changelist(id) {
      getSupplyclassifyid(id).then((res) => {
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
    },
    goCircleDetails(item) {
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      let routeData = this.$router.resolve({
        name: "circleDetailsPage",
        query: {
          id: item.id || item.Id,
          source: Number(item.source),
          title: item.title || item.Title,
        },
      });
      window.open(routeData.href, "_blank");
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`);
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id,
          type,
          title: item.title || item.Title,
        },
      });
      window.open(routeData.href, "_blank");
    },
    start() {
      this.getFav();
      getSupplyclassifyid(0).then((res) => {
        console.log("供需类别列表", res);
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;

        const ids = this.mySupplylist.map((i) => i.id);
        console.log(ids);
        this.Supplylist.forEach((aitem) => {
          if (ids.includes(aitem.id)) {
            aitem.isFav = true;
          } else {
            aitem.isFav = false;
          }
          console.log(aitem);
        });
        console.log(this.Supplylist);

        // this.Supplylist.forEach((item) => {
        //   let hasSameId = ids.some(item.id);
        //   if (hasSameId) {
        //     this.$set(item, "isFav", true);
        //   }

        // });
        console.log(this.Supplylist);
        //         if(this.Supplylist.some(item => ids.includes(item.id))){
        //            this.Supplylist.forEach(item=>{
        // this.$set(item,'isFav',true)
        //            })

        // console.log(this.Supplylist)
        //         }
        // const hasSameItem = this.Supplylist.some(item => ids.includes(item.id))
        // console.log(hasSameItem)
      });
      getSupplyclassification().then((res) => {
        console.log("供需分类", res);
        this.classifylist = res.data;
        this.resultlist = res.data[0].children;
      });
      gethotsupply().then((res) => {
        console.log("热门供需", res);
        this.hotsupplylist = res.data.slice(0, 4);
      });
    },
    dianzan(articleId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        putLike(articleId).then((res) => {
          console.log(res);
          this.changelist(this.saveClassId);
        });
      } else {
        this.$router.push("/login");
        // this.$message({
        //   message: "请先登陆才能点赞",
        //   type: "warning",
        // });
        //    this.router.replace({
        //   name: "/login",
        //   query: { redirect: router.currentRoute.name }
        // });
      }
    },
    shoucang(articleId) {
      let username = window.localStorage.getItem("userName");
      if (username) {
        putFav(articleId).then((res) => {
          console.log(res);
          this.start();
          // this.changelist(this.saveClassId);
        });
      } else {
        this.$router.push("/login");
        this.$message({
          message: "您还没有登录，请先登陆才能收藏该文章",
          type: "warning",
        });
      }
    },
    getFav() {
      fav().then((res) => {
        console.log("我的收藏", res);
        this.mySupplylist = res.data;
      });
    },
  },
  mounted() {
    this.start();
    this.getgrouplist();
  },
};
</script>

<style lang="less" scoped>
.supplydemand {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;

  .supplydemand-left {
    width: 100%;
    margin-right: 20px;

    .supplydemand-left-header {
      background: white;

      .first-classify-box {
        font-size: 16px;
        padding: 20px 20px 0 20px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #e9e9e9;
      }

      .first-classify {
        margin-right: 30px;
        margin-left: 20px;
        padding-bottom: 10px;
        cursor: pointer;
      }

      .active-classifytext {
        // font-weight: bold;
        border-bottom: 4px solid #314483;
        color: #314483;
      }

      .second-classify-box {
        padding: 10px 30px;
        width: 100%;
        margin-top: 10px;
        display: flex;

        .second-classify {
          cursor: pointer;
          font-size: 14px;
          margin-right: 20px;
          color: #999;
          padding: 8px 15px;
        }

        .active-second-classify {
          color: #fff;
          font-weight: 400;
          background: #314483;
          border-radius: 3px;
        }
      }
    }

    .message-totle {
      padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }

    .main-body {
      background: white;

      .main-body-items {
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;

        .title-box {
          display: flex;

          .icon {
            .title-icon {
              font-size: 12px;
              padding: 2px 5px;
              position: relative;
              // top: -2px;
              font-weight: normal;
              color: white;
              margin-right: 5px;
            }

            .gong {
              background: #314483;
            }

            .xu {
              background: #28b28b;
            }

            .pin {
              background: #8169ff;
            }
          }

          .title {
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
          }

          .title:hover {
            color: #3d55a7;
          }
        }

        .author-details {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 14px;
          color: #666;
          line-height: 12px;
          align-items: center;

          .author-box {
            display: flex;

            // justify-content: space-between;
            .author-name {
              padding: 0.1875rem 0.625rem;
              line-height: 1.25rem;
              text-align: center;
              background: #f5f5f5;
              color: #314483;
              // border: 0.0625rem solid #314483;
              // margin-left: 0.625rem;
            }

            .author-time {
              line-height: 1.7rem;
              margin-left: 20px;
            }
          }

          .button-share {
            p {
              height: 24px;
              line-height: 24px;
              margin: 0;
              font-size: 11px;
              background: #f5f6f6;
              padding: 2px 0px 2px 12px;
              border-radius: 15px;
              cursor: pointer;

              .iconfont {
                margin-left: 5px;
                background: #d8d8d8;
                font-size: 15px;
                padding: 7px;
                color: white;
                border-radius: 50%;
                cursor: pointer;
                right: 0;
              }
            }
          }

          .author-img {
            margin-top: 7px;
            margin-right: 10px;
            width: 24px;
            height: 24px;
          }

          .Operationtype {
            display: flex;
            font-size: 14px;
            color: #999;
            cursor: pointer;

            div {
              padding: 10px 25px;
              background: #fff;
              color: #525252;
              font-size: 14px;
              margin-right: 20px;
              border: 1px solid #ededed;
              display: flex;
              align-items: flex-end;

              span {
                margin-left: 10px;
              }
            }

            // div:nth-child(1) {
            //   padding: 10px 20px;
            //   background: #f5f6f6;
            //   color: #314483;
            //   font-size: 14px;
            // }
            // div {
            //   padding: 10px;
            //   margin-right: 20px;
            // }
            .count {
              margin-left: 10px;
            }
          }
        }

        .textarea-box {
          display: flex;
          font-size: 15px;
          color: #333;
          margin-top: 15px;

          .img-box {
            img {
              width: 178px;
            }
          }
        }

        .textarea-details {
          padding-left: 10px;
          width: 100%;

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin: 0;
            line-height: 25px;
          }

          p:nth-child(2) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }

        .textarea-date-box {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          div:nth-child(2) {
            font-size: 14px;
            color: #999;
          }

          .Operationtype {
            display: flex;
            font-size: 14px;
            color: #999;
            cursor: pointer;

            div {
              padding: 10px 20px;
              background: #f5f6f6;
              color: #314483;
              font-size: 14px;
              margin-right: 20px;

              span {
                margin-left: 5px;
              }
            }

            // div:nth-child(1) {
            //   padding: 10px 20px;
            //   background: #f5f6f6;
            //   color: #314483;
            //   font-size: 14px;
            // }
            // div {
            //   padding: 10px;
            //   margin-right: 20px;
            // }
            .count {
              margin-left: 5px;
            }
          }
        }
      }

      :hover {
        cursor: pointer;
        background: #fafafa;
      }
    }
  }

  .supplydemand-right {
    width: 40%;

    .demand-add {
      display: flex;
      margin-bottom: 5px;

      .mc-full-button {
        width: 100%;
        height: 56px;
        background-image: -webkit-gradient(linear,
            left top,
            right top,
            from(#005fb4),
            to(#005fb4));
        background-image: linear-gradient(90deg, #005fb4, #005fb4);
        border-radius: 8.01px;
        // padding-top: 16px;
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .mc-full-button-icon {
          display: flex;
          align-items: center;

          img {
            margin-right: 17.7px;
            width: 24px;
            height: 24px;
          }

          span {
            color: #fff;
            font-size: 18.75px;
            letter-spacing: 0;
            line-height: 26px;
          }
        }
      }
    }

    .release-button {
      background: white;
      height: 40px;
      // background-image: linear-gradient(90deg, #005fb4, #005fb4);
      // border-radius: 8.01px;
      background: #314483;
      padding-top: 16px;
      cursor: pointer;
      color: white;
      text-align: center;
    }

    .hot-demand {
      background: white;

      // margin-top: 20px;
      .hot-demand-header {
        padding: 16px;
        font-size: 16px;
        border-bottom: 1px solid #e9e9e9;

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }

      .hot-demand-item {
        padding: 20px;
        background: white;
        display: flex;
        cursor: pointer;

        &:hover {
          background: #fafafa;
        }

        .icon-point {
          background: #005fb4;
          color: white;
          // width: 16px;
          height: 15px;
          font-size: 11px;
          padding: 2px 4px;
          border-radius: 2px;
          margin-right: 10px;
          // margin-top: 4px;
        }

        .icon-need-point {
          background: #28b28b;
          color: white;
          width: 17px;
          height: 17px;
          font-size: 12px;
          padding: 2px 4px;
          border-radius: 2px;
          margin-right: 10px;
          margin-top: 4px;
        }

        .hot-demand-title {
          font-size: 15px;
          line-height: 20px;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }

    .group-box {
      background: white;
      margin-top: 20px;

      .group-header {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;

        .see-more {
          line-height: 25px;
          padding-right: 20px;
          color: #9a9a9a;
        }

        border-bottom: 1px solid #e9e9e9;

        .header-title {
          font-size: 16px;
          color: #0b1d47;
          margin-top: 0;
          margin-left: 20px;
          margin-bottom: 20px;

          .icon {
            width: 18px;
            height: 18px;
            margin-right: 15px;
            position: relative;
            top: 5px;
          }

          span {
            font-size: 18px;
            font-weight: normal;
            color: #707070;
          }
        }

        .visiting-card {
          height: 20px;
          line-height: 20px;
          padding: 5px 15px;
          margin-top: 0;
          font-size: 14px;
          border-radius: 15px;
          margin-left: 4%;
          cursor: pointer;
        }

        .visiting-card-active {
          background: #b7d9ef65;
        }

        .wechat-group {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          padding: 5px 15px;
          border-radius: 15px;
          margin-left: 15px;
          cursor: pointer;
        }

        .wechat-group-active {
          background: linear-gradient(231deg, #2fb6b649 0%, #128f9325 100%);
        }
      }

      .group-item {
        width: 100%;

        .group-items {
          cursor: pointer;
          padding: 20px 20px;
          display: flex;
          position: relative;
          align-items: flex-end;

          .icon-a-zu337 {
            width: 20px;
            height: 20px;
            display: none;
            position: relative;
            top: 0px;
          }

          &:hover {
            padding: 20px 20px;
            background: #fafafa;

            .join {

              // background: #31448342;
              .iconfont {
                color: #f08200;
              }
            }

            .title {
              color: #314483;

              // font-weight: bold;
              cursor: auto;
            }

            // .icon-a-zu338 {
            //   display: none;
            // }
            // .icon-a-zu337 {
            //   display: block;
            // }
          }

          i {
            font-size: 20px;
          }

          .title {
            margin-left: 20px;
            margin-top: 2px;

            p {
              width: 210px;
              margin: 0;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .join {
            padding: 5px 15px;
            position: absolute;
            right: 10px;
            font-size: 14px;
            text-align: center;

            .iconfont {
              color: #cbcbcb;
              font-size: 16px;
            }
          }
        }
      }
    }

    .follow-type {
      background: white;
      margin-top: 20px;

      .follow-type-header {
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;

        .join-people {
          display: flex;

          div {
            font-weight: bold;
            margin-right: 20px;
            color: #9a9a9a;
            cursor: pointer;
          }

          .active-people {
            color: #262626;
          }
        }

        .cheacd-all {
          color: #005fb4;
          font-size: 14px;
        }
      }

      .follow-type-items {
        padding: 20px;
        display: flex;

        .items-img {
          margin-right: 10px;

          img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
          }
        }

        .user-introduce {
          width: 50%;
          margin-right: 10%;

          p {
            margin: 0;
          }

          p:nth-child(2) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 14px;
            margin-top: 7px;
          }
        }

        .add-friends {
          width: 60px;
          height: 25px;
          text-align: center;
          line-height: 25px;
          padding: 3px;
          border-radius: 5px;
          font-size: 12px;
          margin-top: 10px;
          // border: 1px solid #005fb4;
          border: 1px solid #444444;
          // color: #005fb4;
          color: #434343;
          background: white;
          cursor: pointer;

          .iconfont {
            font-weight: bold;
          }
        }
      }

      .follow-type-items:nth-child(odd) {
        background: #e9e9e961;
      }
    }

    .cooperation {
      margin-top: 20px;
      background: white;

      .cooperation-header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;

        div:nth-child(1) {
          font-weight: bold;
        }

        div:nth-child(2) {
          font-size: 14px;
          color: #005fb4;
          font-weight: 400;
        }
      }

      .advertisement {
        padding: 20px;
        display: flex;
        border-bottom: 1px solid #e9e9e9;
        font-size: 14px;

        div:nth-child(1) {
          margin-right: 20px;
        }
      }

      .official-account {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        div:nth-child(1) {
          font-weight: bold;
          font-size: 14px;
        }

        div:nth-child(2) {
          font-size: 12px;
          color: #005fb4;
          padding: 5px 15px;
          border-radius: 5px;
          margin-top: -5px;
          border: 1px solid #005fb4;
          cursor: pointer;

          &:hover {
            background: #005fb4;
            color: white;
          }
        }
      }
    }

    .join-Groupchat {
      margin-top: 20px;
      background: white;

      div:nth-child(1) {
        padding: 20px;
      }

      .ewmcode-box {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        .ewmcode {
          width: 100%;
          padding: 20px;
          text-align: center;
          cursor: pointer;
          position: relative;
          top: -20px;

          &:nth-child(2) {
            &:hover {
              .lxrewm {
                display: block;

                img {
                  width: 100%;
                }
              }
            }
          }

          &:nth-child(1) {
            &:hover {
              .gxqewm {
                display: block;

                img {
                  width: 100%;
                }
              }
            }
          }

          .gxqewm,
          .lxrewm {
            width: 120px;
            height: 120px;
            position: absolute;
            background: white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.322);
            top: -150px;
            display: none;
            right: -58px;
          }
        }

        .ewmcode:nth-child(1) {
          border-right: 1px solid #e9e9e9;
        }
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }

    /deep/ .active {
      color: white;
      background: #314483;
    }

    /deep/ li:hover {
      color: white;
      background: #314483;
    }

    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }

    /deep/ .btn-next {
      padding: 0;
    }

    /deep/ .btn-prev {
      padding: 0;
    }

    /deep/ button:hover {
      color: white;
      background: #314483;
    }

    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>
